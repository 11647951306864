// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/modules/customer/fetchers/customers-list-fetcher.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/modules/customer/fetchers/customers-list-fetcher.tsx");
  import.meta.hot.lastModified = "1734490853097.8928";
}
// REMIX HMR END

import React from 'react';
import { useFetcher, useRevalidator } from '@remix-run/react';
import { json } from '@remix-run/server-runtime';
import { LogicalOperator, SortOrder } from '~admin/generated/graphql';
import { convertOptionsToDataObject, formDataSearchOptions } from '~common/utils/searchOptions';
import { list } from '~admin/providers/customer/provider';
import { config } from '../config';
export async function action({
  request
}) {
  let error;
  let data;
  const formData = await request.formData();
  try {
    const {
      sort,
      skip,
      take,
      filterOperator,
      filter
    } = formDataSearchOptions(formData);
    data = await list({
      filter,
      filterOperator: LogicalOperator.Or,
      skip,
      sort,
      take
    }, {
      request
    });
  } catch (err) {
    error = err.message;
  }
  return json({
    data: data?.customers,
    error
  });
}
export const CustomersFetcher = ({
  defaultFilter,
  initialItems = [],
  initialItemCount,
  initialItem,
  children
}) => {
  _s();
  const [exception, setException] = React.useState();
  const fetcher = useFetcher({
    key: 'customers'
  });
  const revalidator = useRevalidator();
  const [items, setItems] = React.useState(initialItem ? [initialItem, ...initialItems] : initialItems);
  const [itemCount, setItemCount] = React.useState(initialItem ? (initialItemCount || initialItems.length) + 1 : initialItemCount || initialItems.length);
  const {
    data,
    state
  } = fetcher;
  React.useEffect(() => {
    if (!data || data.error || state === 'loading') {
      return;
    }
    if (data) {
      setException(undefined);
      setItems(data.data.items);
      setItemCount(data.data.totalItems);
    }
  }, [data, state]);
  React.useEffect(() => {
    if (data?.error) {
      setException(data.error);
    }
  }, [data]);
  const handleSearch = React.useCallback(formObj => {
    fetcher.submit({
      ...(formObj || {})
    }, {
      action: `${config.apiPath}/list`,
      method: 'POST'
    });
  }, []);
  const handleSearchByQuery = React.useCallback(query => {
    if (query?.length <= 2) {
      return;
    }
    const params = convertOptionsToDataObject({
      filter: {
        firstName: {
          contains: query
        },
        lastName: {
          contains: query
        },
        emailAddress: {
          contains: query.toLocaleLowerCase()
        }
      },
      take: 12,
      sortColumn: 'lastName',
      sortDirection: SortOrder.Asc
    });
    handleSearch({
      ...params,
      ...defaultFilter
    });
  }, [defaultFilter]);
  const handleChangeOptions = React.useCallback(options => {
    const params = convertOptionsToDataObject(options);
    handleSearch({
      ...params,
      ...defaultFilter
    });
  }, [defaultFilter]);
  const handleReload = React.useCallback(() => {
    handleSearch();
  }, [handleSearch]);
  React.useEffect(() => {
    if (revalidator.state === 'loading') {
      handleReload();
    }
  }, [revalidator.state]);
  return children({
    items,
    itemCount,
    exception,
    loading: state !== 'idle',
    onSearch: handleChangeOptions,
    onSearchByQuery: handleSearchByQuery,
    onReload: handleReload
  });
};
_s(CustomersFetcher, "+ds1lqlGbLzS6Jw9jcV9LFOquBU=", false, function () {
  return [useFetcher, useRevalidator];
});
_c = CustomersFetcher;
var _c;
$RefreshReg$(_c, "CustomersFetcher");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;